import { http } from '../http';

export interface IGetSurveyParams {
  subscription_status: boolean;
  country: string;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IGetSurveyData {
  banner_header:string;
  banner_text:string;
  id:number;
  reward: number;
  url: string;
  fields:{ name:string, value:string }[]
}

export const getSurvey = (params: IGetSurveyParams) =>
  http.get('/survey', { params });
