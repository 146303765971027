import React from 'react';
import classNames from 'classnames/bind';
import { format } from 'date-fns';
import { Icon } from 'src/components/Icon';
import { Button } from 'src/components/Button';

import { useSearchQuery } from 'src/hooks/useSearchQuery';
import { useAccountSelect } from 'src/hooks/useAccount';
import {
  analyticsEvents,
  ESubscriptionReason,
} from 'src/services/analytics/events';

import styles from './SubcribleBanner.module.scss';

import { SubscribleBannerNewPricing } from './SubscribleBannerNewPricing';
import { useRouterPaywallSubscription } from 'src/hooks/route/useRoutePaywallSubscription';
import { useDialogSelect } from 'src/hooks/useDialog';

const sx = classNames.bind(styles);

export interface ISubcribleBannerProps {
  variant?: 'inline';
  text?: string;
  isViewInfo?: boolean;
  mode?: 'chat' | 'navigation' | 'profile';
  reason: `${ESubscriptionReason}`;
}

export const SubcribleBanner = (props: ISubcribleBannerProps) => {
  const {
    reason,
    variant,
    text = 'Get more with',
    isViewInfo = false,
    mode,
  } = props;
  const isUser = useAccountSelect.isUser();
  const isSubscribled = useAccountSelect.isSubscribled();
  const subscription = useAccountSelect.subscription();
  const pricingRules = useAccountSelect.pricingRules();
  const isUpgradeToSexMode = useAccountSelect.isUpgradeToSexMode();
  const routerPaywallSubscription = useRouterPaywallSubscription();
  const bot = useDialogSelect.bot();
  const { setQuery } = useSearchQuery();

  const handleOpenPaywall = () => {
    routerPaywallSubscription.open({
      botName: bot?.name,
      dialogId: bot?.id.toString(),
      priority_category: bot.priority_category,
      reason: reason,
    });
  };

  if (isSubscribled) {
    if (!isViewInfo) return <></>;

    if (subscription?.payment_provider === 'stripe') {
      return (
        <>
          {pricingRules?.flow === 'new_pricing' && (
            <SubscribleBannerNewPricing
              reason={reason}
              mode={
                mode ? mode : variant === 'inline' ? 'profile' : 'navigation'
              }
            />
          )}

          <div className={sx('container', variant)}>
            <div className={sx('title')}>
              <span className={sx('text')}>
                Premium {variant === 'inline' ? 'subscription' : ''}{' '}
                {subscription?.end_datetime
                  ? format(new Date(subscription?.end_datetime), 'dd.MM.yyyy')
                  : 'lifetime'}
              </span>
            </div>
            <a
              href={`${window.settings.MIRROR_CHECKOUT_SESSION_URL}?redirect=subscription-edit`}
              target="_blank"
              rel="noreferrer"
              className={sx('link')}
            >
              <Button
                size="sm"
                variant="filled"
                color="secondary"
                className={sx('editButton')}
              >
                Edit
              </Button>
            </a>
          </div>
        </>
      );
    }

    if (subscription?.payment_provider === 'paypal') {
      return (
        <div className={sx('container', variant)}>
          <div className={sx('title')}>
            <span className={sx('text')}>
              Premium {variant === 'inline' ? 'subscription' : ''}{' '}
            </span>
          </div>
        </div>
      );
    }
  }

  if (pricingRules?.flow === 'new_pricing') {
    return (
      <SubscribleBannerNewPricing
        reason={reason}
        mode={mode ? mode : variant === 'inline' ? 'profile' : 'navigation'}
      />
    );
  }

  return (
    <div className={sx('container', variant)}>
      <div className={sx('title')}>
        <span className={sx('text')}>{text}</span>
        <span className={sx('icon')}>
          <Icon name="Crown" />
        </span>
        <span className={sx('grade')}>Premium</span>
      </div>

      <Button className={sx('button')} onClick={handleOpenPaywall}>
        Subscribe
      </Button>
    </div>
  );
};
