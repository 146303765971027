import { IGlobalStore } from 'src/types/store';
import {
  TStateCommunications,
  createStateSagaCommunications,
} from 'src/helpers/redux';
import { actions as sagaActions, PREFIX } from './actions';

import * as paymentServiceTypes from 'src/services/RomanticAI/payment/types';

export interface IStateData {
  products:Record<string, paymentServiceTypes.IGetProductsData>
  productsGroups: paymentServiceTypes.IGetProductsGroupsData
  checkoutSession?: paymentServiceTypes.IPostCreateCheckoutSessionData;
  subscription?: paymentServiceTypes.IGetSubscriptionData;
}

export interface IState {
  data: IStateData;
  communication: TStateCommunications<typeof sagaActions>;
}

export const getInitialData = (): IStateData => {
  return {
    products: {},
    productsGroups:{
      old_pricing_subscriptions:[],
      old_pricing_hearts:[],
      hearts:[],
      sex_mode_subscriptions: [],
      subscriptions:  []
    },
    checkoutSession: undefined,
    subscription: undefined,
  };
};

export const initialState: IState = {
  data: getInitialData(),
  communication: createStateSagaCommunications(sagaActions),
};

export type TGlobalStateWithService = IGlobalStore & {
  [PREFIX]: IState;
};

export { PREFIX };
