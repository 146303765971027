import { createPhaseAction } from 'src/helpers/redux';
import * as firebaseService from 'src/services/RomanticAI/firebase';
import * as paymentServiceTypes from 'src/services/RomanticAI/payment/types';
import { ISuperOffer } from 'src/services/RomanticAI/session';
import * as botServiceTypes from 'src/services/RomanticAI/bot/types';
import * as userService from 'src/services/RomanticAI/user';

export const PREFIX = 'account';
export const SAGA = `${PREFIX}/saga`;

export const actions = {
  appReady: createPhaseAction<void>(`${SAGA}/appReady`),


  sessionSetup: createPhaseAction<void>(`${SAGA}/sessionSetup`),
  accountSetup: createPhaseAction<void>(`${SAGA}/accountSetup`),
  servicesSetup: createPhaseAction<void>(`${SAGA}/servicesSetup`),

  sessionRefresh: createPhaseAction<void>(`${SAGA}/sessionRefresh`),

  sessionRefreshJwt: createPhaseAction<void>(`${SAGA}/sessionRefreshJwt`),

  auth: createPhaseAction<{ provider: firebaseService.TFirebaseProviders }>(`${SAGA}/auth`),

  authStateChanged: createPhaseAction<void>(`${SAGA}/auth/state-changed`),

  getClientEmail: createPhaseAction<void>(`${SAGA}/getClientEmail`),

  logout: createPhaseAction<void>(`${SAGA}/logout`),
  signIn: createPhaseAction<firebaseService.ISignInEmailPasswordPayload>(`${SAGA}/signIn`),
  signUp: createPhaseAction<firebaseService.ISignUpEmailPasswordPayload>(`${SAGA}/signUp`),
  sendEmailVerification: createPhaseAction<void>(`${SAGA}/sendEmailVerification`),
  recoveryPassword: createPhaseAction<firebaseService.IResetPasswordLinkPayload>(`${SAGA}/recoveryPassword`),
  sendChangePasswordLink: createPhaseAction<void>(`${SAGA}/sendChangePasswordLink`),
  signInEmailLink: createPhaseAction<void>(`${SAGA}/signInEmailLink`),
  deleteAccount: createPhaseAction<{ password?: string }>(`${SAGA}/delete-account`),

  updateEmail: createPhaseAction<{ email: string }>(`${SAGA}/update-email`),

  updateProfile: createPhaseAction<{ displayName?: string; photoUrl?: string }>(
    `${SAGA}/update-profile`
  ),

  uploadAvatar: createPhaseAction<{ file: Blob }>(`${SAGA}/upload-avatar`),

  confirmAdult: createPhaseAction<{
    isConfirm: boolean;
    isCoockieConfirm: boolean;
  }>(`${SAGA}/confirma-adult`),

  getSubscription: createPhaseAction<
    | {
      subscription?: paymentServiceTypes.IGetSubscriptionData;
    }
    | undefined
  >(`${SAGA}/getSubscription`),

  getWallet: createPhaseAction<void>(`${SAGA}/getWallet`),

  getWalletPayment: createPhaseAction<void>(`${SAGA}/getWalletPayment`),

  changeWalletBalance: createPhaseAction<{ balance: number }>(
    `${SAGA}/changeWalletBalance`
  ),

  analyticUserProperties: createPhaseAction<void>(`${SAGA}/analyticUserProperties`),
  analyticsUserAuth: createPhaseAction<void>(`${SAGA}/analyticsUserAuth`),

  superOffer: createPhaseAction<ISuperOffer>(`${SAGA}/superOffer`),
  customBotCountUpdate: createPhaseAction<number>(`${SAGA}/customBotCountUpdate`),
  appInstall: createPhaseAction<boolean>(`${SAGA}/appInstall`),
  appNotifyAccept: createPhaseAction<boolean>(`${SAGA}/appNotifyAccept`),
  getAppDailyReward: createPhaseAction<void>(`${SAGA}/getAppDailyReward`),
  postAppDailyReward: createPhaseAction<void>(`${SAGA}/postAppDailyReward`),
  postImpression: createPhaseAction<void>(`${SAGA}/postImpression`),
  addImpression: createPhaseAction<botServiceTypes.IPostBotImpressionParams>(`${SAGA}/addImpression`),
  postHelloBonus:  createPhaseAction<userService.IPostHeloBonusPopupShowParams>(`${SAGA}/postHelloBonus`),
};

