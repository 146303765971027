import {
  call,
  all,
  put,
  takeLeading,
  select,
} from 'redux-saga/effects';
import { ActionPalyoad } from 'src/helpers/redux';

import { facebookPixel } from 'src/services/analytics/providers/FacebookPixel';
import { analyticsEvents } from 'src/services/analytics/events';
import { IResponse } from 'src/services/RomanticAI/http';
import * as surveyService from 'src/services/RomanticAI/survey';
import * as accountStore from 'src/redux/modules/account'
import { actions } from './actions';
import { setters } from './index';
import { selectors } from './selectors';
import { IStateData } from './state';

export default function* sagas() {
  yield all([
    takeLeading(
      actions.getSurvey.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.getSurvey.pending>) {
        try {
          const survey: IResponse<surveyService.IGetSurveyData | undefined > = yield call(
            surveyService.getSurvey,
            action.payload
          );

          yield put(setters.setSurvey(survey.data));

          yield put(actions.getSurvey.fulfilled());
        } catch (error) {
          yield put(actions.getSurvey.rejected(error as Error));
        }
      }
    ),

    takeLeading(
      actions.surveyComplited.pending.toString(),
      function* (action: ActionPalyoad<typeof actions.getSurvey.pending>) {
        try {
          const wallet: accountStore.IStateData['wallet'] = yield select(accountStore.selectors.wallet);
          const survey: IStateData['survey'] = yield select(selectors.survey);

          yield put (accountStore.actions.changeWalletBalance.pending({
            balance:(wallet?.balance ||0) +  (survey?.reward || 0)
          }))
          yield put(setters.setSurvey(undefined));

          yield put(actions.surveyComplited.fulfilled());
        } catch (error) {
          yield put(actions.surveyComplited.rejected(error as Error));
        }
      }
    ),

  ]);
}
