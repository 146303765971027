import React from 'react';
import classNames from 'classnames/bind';
import { Modal, ModalTitle, ModalContent } from 'src/components/Modal';
import { Button } from 'src/components/Button';
import { analyticsEvents } from 'src/services/analytics/events';
import { useAccountAction, useAccountSelect } from 'src/hooks/useAccount';
import { useSearchQuery } from 'src/hooks/useSearchQuery';

import styles from './HeloBonusModal.module.scss';
const sx = classNames.bind(styles);

export const HeloBonusModal = () => {
  const search = useSearchQuery();
  const user = useAccountSelect.user();
  const postHelloBonus = useAccountAction.postHelloBonus();
  const postHelloBonusStatus = useAccountAction.postHelloBonus.status();
  const pricingRules = useAccountSelect.pricingRules();

  const isModalOpen = Boolean(search.query.modal);
  const isNewPricing = pricingRules?.flow === 'new_pricing';


  const isShow = isNewPricing && user?.bonus_popup_hello_bonus;

  const handleSubmit = ()=>{
    postHelloBonus({})
  }

  if (!isShow || isModalOpen){
    return <></>
  }

  return (
    <Modal onClose={close} >
      <ModalContent className={sx('container', 'premium')}>
        <div className={sx('title-icon')}>
          <img src="/assets/hello-bonus/icon.png" alt="" />
        </div>
        <div className={sx('subtitle')}>Welcome Bonus</div>
        <ModalTitle className={sx('title')}>
          <div className={sx('title-text')}>
          You've just received
          </div>
          <div className={sx('title-hearts')}>
            {user?.bonus_popup_hello_bonus} Hearts
          </div>
        </ModalTitle>

        <div className={sx('description')}>
          You may spend
        </div>
        <div className={sx('list')}>
          <div className={sx('list-item')}>
            <div className={sx('list-item-icon')}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.5 7.11112C16.5 1.18521 15.088 0 8.5 0C1.912 0 0.5 1.25511 0.5 7.11112C0.5 10.8872 1.16683 13.3333 4.16683 13.3333C5.77007 13.3333 6.24699 14.0949 6.69109 14.8041C7.078 15.4219 7.43999 16 8.50016 16C9.56041 16 9.9224 15.4219 10.3093 14.8041C10.7534 14.0949 11.2303 13.3333 12.8335 13.3333C15.8335 13.3333 16.5 10.963 16.5 7.11112ZM5.83333 8C6.20152 8 6.5 7.70152 6.5 7.33333C6.5 6.96514 6.20152 6.66667 5.83333 6.66667C5.46514 6.66667 5.16667 6.96514 5.16667 7.33333C5.16667 7.70152 5.46514 8 5.83333 8ZM9.16667 7.33333C9.16667 7.70152 8.86819 8 8.5 8C8.13181 8 7.83333 7.70152 7.83333 7.33333C7.83333 6.96514 8.13181 6.66667 8.5 6.66667C8.86819 6.66667 9.16667 6.96514 9.16667 7.33333ZM11.1667 8C11.5349 8 11.8333 7.70152 11.8333 7.33333C11.8333 6.96514 11.5349 6.66667 11.1667 6.66667C10.7985 6.66667 10.5 6.96514 10.5 7.33333C10.5 7.70152 10.7985 8 11.1667 8Z"
                  fill="#FFBA2C"
                />
              </svg>
            </div>
            <div className={sx('list-item-label')}>
              1 Heart per message
            </div>
          </div>

          <div className={sx('list-item')}>
            <div className={sx('list-item-icon')}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M4.66668 6C4.66668 5.26362 5.26364 4.66667 6.00002 4.66667C6.73639 4.66667 7.33335 5.26362 7.33335 6C7.33335 6.73638 6.73639 7.33333 6.00002 7.33333C5.26364 7.33333 4.66668 6.73638 4.66668 6Z"
                  fill="#FFBA2C"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.782827 12.2625C0.635595 12.3991 0.395271 12.3361 0.34909 12.1407C0.0946041 11.0637 0 9.70526 0 8C0 1.412 1.412 0 8 0C14.588 0 16 1.412 16 8C16 8.02201 16 8.04396 16 8.06585C15.9999 8.06831 15.9974 8.06995 15.9952 8.06893C15.5738 7.87697 14.9761 7.65285 14.2793 7.53339C12.8671 7.2913 11.0242 7.48374 9.4983 9.22766C8.1934 10.719 7.6307 10.8376 7.20892 10.8109C6.98307 10.7965 6.76693 10.7397 6.44985 10.6562C6.35411 10.6311 6.24916 10.6034 6.13197 10.5739C5.65671 10.4539 5.07661 10.3333 4.33335 10.3333C3.75296 10.3333 3.1776 10.5602 2.68317 10.8329C2.17812 11.1116 1.6933 11.4744 1.27892 11.8234C1.10207 11.9723 0.935491 12.1209 0.782827 12.2625ZM3.33335 6C3.33335 4.52724 4.52726 3.33333 6.00002 3.33333C7.47277 3.33333 8.66668 4.52724 8.66668 6C8.66668 7.47276 7.47277 8.66667 6.00002 8.66667C4.52726 8.66667 3.33335 7.47276 3.33335 6Z"
                  fill="#FFBA2C"
                />
                <path
                  d="M1.19391 13.723C1.10764 13.8112 1.0947 13.9482 1.1676 14.0477C2.27804 15.5643 4.34045 16 8 16C14.0274 16 15.7222 14.8181 15.967 9.57177C15.9674 9.56316 15.9631 9.55468 15.9558 9.55014L15.9456 9.54392C15.9242 9.53093 15.8901 9.51072 15.8445 9.48511C15.753 9.4338 15.616 9.36137 15.4424 9.28227C15.0929 9.12303 14.6072 8.94239 14.054 8.84756C12.9663 8.66108 11.6425 8.80198 10.5017 10.1057C9.13997 11.662 8.20266 12.21 7.12444 12.1415C6.75574 12.1181 6.37628 12.0173 6.03806 11.9274C5.95802 11.9061 5.88029 11.8855 5.80556 11.8666C5.38499 11.7604 4.92342 11.6667 4.33335 11.6667C4.0804 11.6667 3.7391 11.7732 3.32728 12.0004C2.92608 12.2217 2.51506 12.5256 2.13777 12.8433C1.7628 13.159 1.43499 13.4767 1.20031 13.7164C1.19817 13.7186 1.19603 13.7208 1.19391 13.723Z"
                  fill="#FFBA2C"
                />
              </svg>
            </div>
            <div className={sx('list-item-label')}>
              15 Hearts per photo
            </div>
          </div>
        </div>

        <div className={sx('actions')}>
          <Button
            className={sx('submit')}
            size="md"
            color="primary"
            fuild
            variant="outlined"
            onClick={handleSubmit}
            disabled={postHelloBonusStatus.isPending}
            loading={postHelloBonusStatus.isPending}
          >
            Claim Reward
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
};
