/* eslint-disable @typescript-eslint/no-empty-interface */
import { http } from './http';
import { IAnalyticsUTM } from '../analytics';

export enum SubscriptionPaywallGroup {
  subscription_paywall_a = 'subscription_paywall_a',
  subscription_paywall_b = 'subscription_paywall_b',
  subscription_paywall_c = 'subscription_paywall_c',
}
export enum ab_hearts_paywall_1_15_0_group {
  a = 'hearts_paywall_1_15_0_a',
  b = 'hearts_paywall_1_15_0_b',
  c = 'hearts_paywall_1_15_0_C',// yes it is
}
export enum ab_subscription_paywall_1_15_0_group {
  a = 'subscription_paywall_1_15_0_a',
  b = 'subscription_paywall_1_15_0_b',
  c = 'subscription_paywall_1_15_0_c',
  d = 'subscription_paywall_1_15_0_d',
}
export enum HeartsPaywallGroup {
  hearts_paywall_a = 'hearts_paywall_a',
  hearts_paywall_b = 'hearts_paywall_b',
}
export enum ab_subscription_paywall_02_2024_group {
  a = 'subscription_paywall_02_2024_a',
  b = 'subscription_paywall_02_2024_b',
  c = 'subscription_paywall_02_2024_c',
}
export enum ab_blurred_sexting_messages_16_02_2024 {
  a = 'ab16_02_2024_blurred_a',
  b = 'ab16_02_2024_unblurred_b'
}
export enum user_image_custom_bot {
  disabled = 'disabled',
  enabled = 'enabled',
}

export enum pwa_june_2024 {
  A = 'A', //- группа без PWA
  B = 'B' // - группа с PWA
}

export enum lifetime_and_1m_hearts {
  ab_lifetime_and_1m_hearts_a = 'ab_lifetime_and_1m_hearts_a', // группа без нового тарифа
  ab_lifetime_and_1m_hearts_b = 'ab_lifetime_and_1m_hearts_b' // группа с новым тарифом
}

export enum ab_photo_romantic {
  a = 'ab_photo_romantic_a',
  b = 'ab_photo_romantic_b' // группа c фото романтиком
}
export interface IUser {
  uuid: string;
  web_balance: string;
  has_subscribe: boolean;
  is_adult_confirm: boolean;
  new_custom_bot_count: number;
  feedback_themes: IUserFeedbackThemeItem[];
  custom_bot: 'old_custom' | 'new_custom';
  hearts_paywall_group: `${HeartsPaywallGroup}` | null;
  subscription_paywall_group: `${SubscriptionPaywallGroup}` | null;
  ab_hearts_paywall_1_15_0_group: `${ab_hearts_paywall_1_15_0_group}` | null;
  ab_subscription_paywall_1_15_0_group: `${ab_subscription_paywall_1_15_0_group}` | null;
  ab_subscription_paywall_02_2024_group: `${ab_subscription_paywall_02_2024_group}` | null;
  ab_blurred_sexting_messages_16_02_2024: `${ab_blurred_sexting_messages_16_02_2024}` | null;
  ab_web_pwa_june_2024: `${pwa_june_2024}` | null;
  ab_lifetime_and_1m_hearts: `${lifetime_and_1m_hearts}` | null;
  paypal_group?: 'enabled' | 'disabled' | null
  user_image_custom_bot: `${user_image_custom_bot}` | null
  context_generation: 'enabled' | 'disabled' | null
  ab_photo_romantic: `${ab_photo_romantic}` | null;
  pricing_rules?: IPricingRules
  bonus_popup_hello_bonus?: number;
}

export interface IPricingRules {
  billing?: {
    hearts_paywall_design: "yes" | 'no'
  }
  chat?: {
    total_messages_before_pw?: number,
    can_view_sexting_image?: boolean,
    can_view_sexting_text?: boolean,
    cost_text_message?: number
    unblur_cost_image?: number
    unblur_cost_text?: number
    can_write_own_bot?:boolean;
    can_unblur_sexting_image?: boolean; // Можно ли разблюривать sexting изображения за сердца, если False, то показывать пейвол апгрейда до секс мода если на изображении is_sexting_image=True
  }
  custom_bot?: {
    can_use_constructor_flow?: boolean,
    can_use_user_image_flow?: boolean
    show_pw_constructor_flow?: boolean;
    show_pw_constructor_user_image_flow: boolean;
  }

  flow?: 'old' | 'new_pricing'
  photo_romantic?: {
    can_use?: boolean
    can_upload_image?: boolean
  }
}

export interface IUserFeedbackThemeItem {
  id: number;
  message_type: 'image' | 'text',
  reaction_type: 'dislike' | 'like'
  name: string
}
export interface IPostUserData extends IUser { }
export interface IPostUserParams {
  query?: IAnalyticsUTM
}
export const postUser = ({ query }: IPostUserParams) =>
  http.post<IPostUserData>(`/user`, undefined, { params: query });

export interface IGetUserData extends IUser { }
export const getEmail = () => http.get<IGetUserData>(`/user/email`);
export const getUser = () => http.get<IGetUserData>(`/user`);

interface IPathUserParams {
  is_adult_confirm: boolean;
  query?: IAnalyticsUTM | undefined
}

export const pathUser = ({ query, ...data }: IPathUserParams) =>
  http.patch(`/user`, data, { params: query });

export const deleteUser = () => http.delete(`/user`);

export const postAppReady = () => http.post(`/app/ready`);

export interface IGetAppDailyRewardParams {
  has_subscribe: boolean;
}

export interface IGetAppDailyRewardData {
  can_earn_today: boolean;
  reward: number;
  schedule: {
    [date:string]: {
      reward:number;
      status: 'waiting' | 'pending' |  'earned'
    }
  };
  today: string;
}

export const getAppDailyReward = (params: IGetAppDailyRewardParams) => http.get<IGetAppDailyRewardData>(`/app/daily-reward`, { params });

export interface IPostAppDailyRewardParams {
  has_subscribe: boolean;
}
export interface IPostAppDailyRewardData {
  balance: number;
}
export interface IPostAppDailyRewardError {
  error_code: "NOT_APPLICABLE_FOR_USER"
}

export const postAppDailyReward = (data: IPostAppDailyRewardParams) => http.post<IPostAppDailyRewardData>(`/app/daily-reward`, data);

export const postUserRegistrationComplite = () => http.post('/user/registration/complete')

export interface IUserLoginParams {
  flow: 'firebase',
  client_parameters?: Record<string,unknown>
  flow_parameters: {
    firebase_token: string;
  }
}

export interface IUserLoginData {
  access_token: string;
  refresh_token: string;
  uuid: string;
}

export const userLogin = (data: IUserLoginParams) => http.post<IUserLoginData>('/user/login', data)

export interface IUserRefreshTokensParams {
  refresh_token: string;
}
export interface IUserRefreshTokensData {
  access_token: string;
  refresh_token: string;
}

export const userRefreshTokens = (data: IUserRefreshTokensParams) => http.post<IUserRefreshTokensData>('/user/refresh-tokens', data)

export interface IPostHeloBonusPopupShowParams { }

export const postHeloBonusPopupShow = ({ ...data }: IPostHeloBonusPopupShowParams) =>
  http.post(`/user/hello-bonus-popup/shown`, data);
