import { useSearchQuery } from 'src/hooks/useSearchQuery';
import { ESubscriptionReason } from 'src/services/analytics/events';


export interface ISearchQueryParams extends IUseRouterPaywallSubscriptionOpen {
  modal?: string,
}
export interface IUseRouterPaywallSubscriptionOpen {
  dialogId?: string;
  reason?: `${ESubscriptionReason}`,
  botName?: string,
  redirectModal?: string;
  redirectPage?: string;
  priceId?: string;
  already?: string;
  priority_category?: string;
}
export const useRouterPaywallSubscription = ()=> {
  const { query, setQuery, removeQuery } = useSearchQuery<ISearchQueryParams>();

    const open = (params:IUseRouterPaywallSubscriptionOpen)=> {
      setQuery({
        modal:'paywall-subscription',
        ...params
      })
    }
   const close = () => {
     removeQuery([
       'modal',
       'dialogId',
       'reason',
       'botName',
       'redirectModal',
       'priceId',
       'already',
       'priority_category',
     ]);
   };

   return {
    query,
    setQuery,
    removeQuery,
    open,
    close
   }
}