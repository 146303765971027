import { createPhaseAction } from 'src/helpers/redux';

import * as surveyService from 'src/services/RomanticAI/survey';

export const PREFIX = 'survey';
export const SAGA = `${PREFIX}/saga`;

export const actions = {
  getSurvey: createPhaseAction<surveyService.IGetSurveyParams>(`${SAGA}/getSurvey`),
  surveyComplited: createPhaseAction<void>(`${SAGA}/surveyComplited`),
};
