import { createSelector } from 'reselect';
import { createCommunicationSelectors } from 'src/helpers/redux';
import { IState, PREFIX, TGlobalStateWithService } from './state';
import { actions } from './actions';
import {
  IGetProductsDataProduct,
  IGetProductsDataSubscription,
  IGetProductsDataExtra,
} from 'src/services/RomanticAI/payment';


export const selectData = (state: TGlobalStateWithService): IState['data'] =>
  state[PREFIX].data;

export const selectCommunication = (
  state: TGlobalStateWithService
): IState['communication'] => state[PREFIX].communication;

export const communications = createCommunicationSelectors<
  TGlobalStateWithService,
  IState['communication'],
  typeof actions
>(selectCommunication, actions);

export const selectors = {
  subscription: createSelector(selectData, (state) => state.subscription),
  checkoutSession: createSelector(selectData, (state) => state.checkoutSession),
  paypalSubscriptionProducts: createSelector(selectData, (state) => {
    const paypalProducts = state.products['paypal'];
    if (!paypalProducts) return [];

    const productsSubscription = paypalProducts?.products
      ?.filter((item) => item.product_type === 'SUBSCRIPTION')
      ?.map((item) => ({ ...item, payment_type: 'order' }))


    const currentProducts = [
      ...paypalProducts?.subscriptions?.map((item) => ({ ...item, payment_type: 'subscription' })),
      ...productsSubscription,
    ]

    return currentProducts
  }),

  paypalHeartsProducts: createSelector(selectData, (state) => {
    const paypalProducts = state.products['paypal'];
    if (!paypalProducts) return [];

    const productsHearts = paypalProducts?.products
      ?.filter((item) => item.product_type !== 'SUBSCRIPTION')
      ?.map((item) => ({ ...item, payment_type: 'order' }))

    return productsHearts
  }),

  productsGroups: createSelector(selectData, (state) => state.productsGroups),
  productsGroupsSubscription: createSelector(selectData, (state) =>
    state.productsGroups.subscriptions
      .filter(product => product?.info?.enable)
      .sort((a, b) => a.info.priority_index - b.info.priority_index)
  ),
  productsGroupsSubscriptionOld: createSelector(selectData, (state) =>
    state.productsGroups.old_pricing_subscriptions
      .filter(product => product?.info?.enable)
      .sort((a, b) => a.info.priority_index - b.info.priority_index)
  ),
  productsGroupsSubscriptionSexMode: createSelector(selectData, (state) =>
    state.productsGroups.sex_mode_subscriptions
      .filter(product => product?.info?.enable)
      .sort((a, b) => a.info.priority_index - b.info.priority_index)
  ),
  productsGroupsSubscriptionAll: createSelector(selectData, (state) =>
    [
      ...state.productsGroups.subscriptions,
      ...state.productsGroups.old_pricing_subscriptions,
      ...state.productsGroups.sex_mode_subscriptions,
    ]),
  productsGroupsHearts: createSelector(selectData, (state) =>
    state.productsGroups.hearts
      .filter(product => product?.info?.enable)
      .sort((a, b) => a.info.priority_index - b.info.priority_index)
  ),
  productsGroupsHeartsOld: createSelector(selectData, (state) =>
    state.productsGroups.old_pricing_hearts
      .filter(product => product?.info?.enable)
      .sort((a, b) => a.info.priority_index - b.info.priority_index)
  ),
  productsGroupsHeartsAll: createSelector(selectData, (state) =>
    [
      ...state.productsGroups.hearts,
      ...state.productsGroups.old_pricing_hearts,
    ]
      .filter(product => product?.info?.enable)
      .sort((a, b) => a.info.priority_index - b.info.priority_index)
  ),
};

