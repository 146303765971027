import {
  call,
  all,
  put,
  takeLeading,
  takeEvery,
  delay,
  select,
} from 'redux-saga/effects';
import { ActionPalyoad } from 'src/helpers/redux';

import { facebookPixel } from 'src/services/analytics/providers/FacebookPixel';
import { analyticsEvents, ESubscriptionReason } from 'src/services/analytics/events';
import { IResponse } from 'src/services/RomanticAI/http';
import * as paymentService from 'src/services/RomanticAI/payment';

import { setSession } from 'src/services/RomanticAI/session';
import * as accountStore from 'src/redux/modules/account';
import * as dialogStore from 'src/redux/modules/dialog';

import { actions } from './actions';
import { setters } from './index';
import { getHeartProduct } from 'src/services/RomanticAI/products'
import queryString from 'query-string';

export default function* sagas() {
  yield all([
    takeLeading(
      actions.postCreateCheckoutSession.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.postCreateCheckoutSession.pending>
      ) {
        try {
          const { price_id, dialogId, reason, is_old, is_upgrade, paywall_name, priority_category,botName } = action.payload;
          const pathname = dialogId ? `chats/${dialogId}` : '';
          const query = queryString.stringify({
            modal: "subscription",
            dialogId,
            reason,
            is_upgrade,
            priority_category,
            botName
          })

          const redirectUrl = `${location.protocol}//${location.hostname}${location.port ? ':' + location.port : ''
            }/${pathname}?${query}`;

          const payload = btoa(JSON.stringify({
            redirectUrl
          })).replace('eyJ','egI')


          const success_url = `${window.settings.MIRROR_CHECKOUT_SESSION_URL}/?checkout-session-success=${payload}`;

          const bot: dialogStore.IStateData['bot'] = yield select(
            dialogStore.selectors.bot
          );

          try {
            setSession({ superOffer: undefined })
            analyticsEvents.subscriptionCheckout({
              reason: reason as ESubscriptionReason,
              bot_name: botName || '',
              bot_category: priority_category,
            })

          } catch (e) {
            console.error(e)
          }
          const metadata:paymentService.IPostCreateCheckoutSessionParams['metadata'] = {
            bot_chat_user_id:dialogId,
            reason: reason || '',
            paywall_name: paywall_name,
          }

          const checkoutSession: IResponse<paymentService.IPostCreateCheckoutSessionData> =
            yield call(paymentService.postCreateCheckoutSession, {
              price_id,
              success_url: success_url,
              is_old,
              is_upgrade,
              metadata
            });

          if (checkoutSession.data.url){
            if (is_upgrade){
              const payload = btoa(JSON.stringify({
                redirectUrl:checkoutSession.data.url
              })).replace('eyJ','egI')


              const success_url = `${window.settings.MIRROR_CHECKOUT_SESSION_URL}/?checkout-session-success=${payload}`;

              window.open(success_url)
            } else {
              // yield delay(500);
              const data = btoa(JSON.stringify(checkoutSession.data))
              window.location.replace(`${window.settings.MIRROR_CHECKOUT_SESSION_URL}?checkout-session=${data}`);
            }
          }

          yield put(accountStore.actions.accountSetup.pending())
          yield put(actions.postCreateCheckoutSession.fulfilled());
        } catch (error) {
          yield put(actions.postCreateCheckoutSession.rejected(error as Error));
        }
      }
    ),


    takeLeading(
      actions.postHeartCreateCheckoutSession.pending.toString(),
      function* (
        action: ActionPalyoad<
          typeof actions.postHeartCreateCheckoutSession.pending
        >
      ) {
        try {
          const { price_id, dialogId, reason, count, botName, bot_category } = action.payload;
          const query = queryString.stringify({
            modal: "paywall-heart-result",
            dialogId,
            reason,
            count,
            bot_category,
            botName,
            price_id
          })
          const pathname = dialogId ? `chats/${dialogId}` : '';
          const redirectUrl = `${location.protocol}//${location.hostname}${location.port ? ':' + location.port : ''
            }/${pathname}?${query}`;

          const payload = btoa(JSON.stringify({
              redirectUrl
            })).replace('eyJ','egI')

          const success_url = `${window.settings.MIRROR_CHECKOUT_SESSION_URL}/?checkout-session-success=${payload}`;

          const metadata:paymentService.IPostCreateCheckoutSessionParams['metadata'] = {
            bot_chat_user_id:dialogId,
            reason: reason || '',
            paywall_name: 'hearts',
          }
          const checkoutSession: IResponse<paymentService.IPostCreateCheckoutSessionData> =
            yield call(paymentService.postCreateCheckoutSession, {
              price_id,
              success_url: success_url,
              metadata,
            });
            analyticsEvents.coinsCheckout({
              reason: reason as ESubscriptionReason,
              bot_name: botName || '',

              bot_category: bot_category,
            })

          const data = btoa(JSON.stringify(checkoutSession.data))
          window.location.replace(`${window.settings.MIRROR_CHECKOUT_SESSION_URL}?checkout-session=${data}`);

          yield put(actions.postHeartCreateCheckoutSession.fulfilled());
        } catch (error) {
          yield put(
            actions.postHeartCreateCheckoutSession.rejected(error as Error)
          );
        }
      }
    ),
    takeLeading(
      actions.getProducts.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.getProducts.pending>
      ) {
        try {
          const products: IResponse<paymentService.IGetProductsData> =
            yield call(paymentService.getProducts, 'paypal');
          yield put(setters.setProducts({ provider: 'paypal', data: products.data }))
          yield put(actions.getProducts.fulfilled());
        } catch (error) {
          yield put(
            actions.getProducts.rejected(error as Error)
          );
        }
      }
    ),
    takeLeading(
      actions.getProductsGroups.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.getProductsGroups.pending>
      ) {
        try {
          const products: IResponse<paymentService.IGetProductsGroupsData> =
            yield call(paymentService.getProductsGroups, action.payload);

          yield put(setters.setProductsGroups(products.data))

          yield put(actions.getProductsGroups.fulfilled());
        } catch (error) {
          yield put(
            actions.getProductsGroups.rejected(error as Error)
          );
        }
      }
    ),
    takeLeading(
      actions.stripeChange.pending.toString(),
      function* (
        action: ActionPalyoad<typeof actions.stripeChange.pending>
      ) {
        try {
          const { price_id, extra } = action.payload
          const suabscription:accountStore.IStateData['subscription'] = yield select(accountStore.selectors.subscription)

          if (suabscription?.price_id === price_id || !extra?.reason) {
            yield put(actions.stripeChange.fulfilled());
            return;
          }

          const pathname = extra.bot_id ? `chats/${extra.bot_id}` : '';
          const query = queryString.stringify({
            modal: "subscription",
            dialogId:extra.bot_id,
            reason: extra.reason,
            priority_category: extra.bot_category,
            botName: extra.bot_name
          })

          const redirectUrl = `${location.protocol}//${location.hostname}${location.port ? ':' + location.port : ''
            }/${pathname}?${query}`;

          window.location.replace(redirectUrl);

          yield put(actions.stripeChange.fulfilled());
        } catch (error) {
          yield put(
            actions.stripeChange.rejected(error as Error)
          );
        }
      }
    ),

  ]);
}