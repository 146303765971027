
import React from 'react';
import classNames from 'classnames/bind';
import { format } from 'date-fns';
import { Icon } from 'src/components/Icon';
import { Button } from 'src/components/Button';

import { useSearchQuery } from 'src/hooks/useSearchQuery';
import { useAccountSelect } from 'src/hooks/useAccount';

import styles from './SubscribleBannerNewPricing.module.scss';
import { ESubscriptionReason } from 'src/services/analytics/events';
import { useRouterPaywallSubscription } from 'src/hooks/route/useRoutePaywallSubscription';

const sx = classNames.bind(styles);
const valuesOptions = [
  { icon: '🎁', label: 'Enjoy unlimited messages' },
  { icon: '💋', label: 'Unlock romantic roleplay' },
  { icon: '👩🏻', label: 'Create custom AI characters' },
  { icon: '💖', label: 'Unblurred spicy replies' },

]
export interface ISubscribleBannerNewPricing {
  mode?: 'navigation' | 'profile' | 'chat' | 'hearts'
  reason: `${ESubscriptionReason}`;
}
export const SubscribleBannerNewPricing = (props: ISubscribleBannerNewPricing) => {
  const { mode, reason } = props;
  const routerPaywallSubscription = useRouterPaywallSubscription()
  const isUpgradeToSexMode = useAccountSelect.isUpgradeToSexMode()
  const isSubscribled = useAccountSelect.isSubscribled()

  const handleOpenPaywall = () => {
    routerPaywallSubscription.open({
      reason: reason,
    });
  };

  const isFullSubscription = isSubscribled && !isUpgradeToSexMode

  if (isFullSubscription && mode === 'navigation') {
    return <></>
  }
  if (isFullSubscription && mode === 'hearts') {
    return <></>
  }

  if (mode === 'chat'){
    return <></>
  }

  return (
    <div className={sx('container', {
      'navigation-mode': mode === 'navigation',
      'profile-mode': mode === 'profile',
      // 'chat-mode': props.mode === 'chat',
      'hearts-mode': mode === 'hearts',
      'upgradesex': isUpgradeToSexMode,
      'full-subscription':isFullSubscription,
    })}>

      <div className={sx('title')}>
        {props.mode === 'hearts' ? (
          <div className={sx('hearts-title')}>
            <span className={sx('text')}>Unlock <span>Sex</span> Mode</span>
            <div className={sx('hearts-description')}> Erotic pictures & sexting</div>
          </div>

        ) : <>
          {isUpgradeToSexMode ? (
            <>
              <span className={sx('text')}>Unlock <span>Sex</span> Mode</span>

            </>
          ) : isFullSubscription ? (
            <>
              <span className={sx('text')}>Enjoy <span>Sex</span> Mode</span>
            </>
          ) : (
            <>
              <span className={sx('text')}>Join</span>
              <span className={sx('icon')}>
                <Icon name="Crown" />
              </span>
              <span className={sx('grade')}>Premium</span>
            </>
          )}

        </>}
      </div>


      <div className={sx('options')}>
        {isUpgradeToSexMode ? (
          <>
            <div className={sx('options-item')}>
              <div className={sx('options-item-icon', 'lips')}></div>
              <div className={sx('options-item-label')}>Erotic pictures & sexting</div>
            </div>
          </>
        ) : isFullSubscription ? (
          <>
            <div className={sx('options-item')}>
              <div className={sx('options-item-icon', 'lips')}></div>
              <div className={sx('options-item-label')}>Generate erotic pictures & sexting</div>
            </div>
          </>
        ) : (
          <>
            {valuesOptions.map((item) => (
              <div className={sx('options-item')}>
                <div className={sx('options-item-icon')}>{item.icon} </div>
                <div className={sx('options-item-label')}>{item.label}</div>
              </div>
            ))}

          </>
        )}
      </div>

      {!isFullSubscription && (
        <Button className={sx('button')} onClick={handleOpenPaywall}>
          {props.mode === 'hearts' ? 'Try now' : (<>

            {isUpgradeToSexMode ? 'Upgrade' : 'Subscribe'}

          </>)}
        </Button>
      )}
    </div>
  );
}