import Cookies from 'js-cookie';
import * as paymentServiceTypes from 'src/services/RomanticAI/payment/types';
import { IAnalyticsUTM } from '../analytics';
import { ESubscriptionReason } from '../analytics/events';
import { IGetAppDailyRewardData } from './user';

const cookieService = Cookies.withAttributes({});

export interface ISession {
  firebaseIdToken?: string;
  clientToken?: string;
  jwt?: {
    access_token: string;
    refresh_token: string;
    updatedAt: number;
    expiration: number;
    uuid: string;
  };
  authClientToken?: string;
  displayName?: string;
  photoUrl?: string;
  email?: string;
  confirmAdult?: boolean;
  messagesTimeHistory?: number[];
  subscription?: paymentServiceTypes.IGetSubscriptionData;
  writeMessage?: Record<string, string>;
  resubsription?: boolean;
  isFirstSubscription?: boolean;
  // chatStoreTooltipClosed?: boolean;
  shareBotTooltipClosed?: boolean;
  subscriptionOfferTimer?: number;
  analyticsUTM?: IAnalyticsUTM;
  emailConfirmTimer?:number;
  superOffer?: ISuperOffer;
  appInstall?: boolean;
  appNotifyAccept?: boolean;
  surveyClosed?:boolean,
  appDailyReward?: IGetAppDailyRewardData;
}

export const getInitialSession = (): ISession => {
  const clientToken = localStorage.getItem('token') || undefined;
  return {
    firebaseIdToken: undefined,
    jwt: undefined,
    clientToken: clientToken || undefined,
    authClientToken: undefined,
    displayName: undefined,
    photoUrl: undefined,
    email: undefined,
    confirmAdult: undefined,
    messagesTimeHistory: undefined,
    subscription: undefined,
    subscriptionOfferTimer:undefined,
    writeMessage: {},
    resubsription: undefined,
    surveyClosed:false,
    isFirstSubscription: undefined,
  };
};
export const isFirstSession = () => !Boolean(cookieService.get('session'));

export const getSession = (): ISession => {
  const sessionData = cookieService.get('session');


  try {
    const session = JSON.parse(sessionData || "{}");

    if (!session.confirmAdult) {
      const sessionStorageData = sessionStorage.getItem('session')

      if (sessionStorageData) {
        const session = JSON.parse(sessionStorageData);
        return {
          ...getInitialSession(),
          ...session,
        };
      }
      return getInitialSession();
    }

    return {
      ...getInitialSession(),
      ...session,
    };
  } catch (e) {
    console.error('invalid session');
    return getInitialSession();
  }
};

export const setSession = (session: ISession) => {
  try {
    const prevSession = getSession() || getInitialSession();
    const toUpdatedSession = { ...prevSession, ...session };

    if (session.hasOwnProperty('clientToken') && !session.clientToken) {
      localStorage.removeItem('token');
      localStorage.removeItem('lastmsg');
      localStorage.removeItem('messages');
    }

    if (toUpdatedSession.confirmAdult) {
      cookieService.set(
        'session',
        JSON.stringify(toUpdatedSession),
        {
          expires: 365,//days
        }
      );
    } else {
      sessionStorage.setItem('session', JSON.stringify(toUpdatedSession))
    }
  } catch (e) {
    console.error(e);
  }
};

export const eternalSessionProperties: Array<keyof ISession> = [
  // 'chatStoreTooltipClosed',
  'isFirstSubscription',
  'subscription',
  'shareBotTooltipClosed',
  'analyticsUTM',
  'messagesTimeHistory',
  'confirmAdult',
  'superOffer',
  'appInstall',
  'appNotifyAccept',
]

const getEternalSession = () => {
  const session = getSession()
  const onlyEternalInitial: ISession = {}
  return eternalSessionProperties.reduce((prev, property) => ({
    ...prev,
    [property]: session[property],
  }), onlyEternalInitial)
}

export const clearSession = (session?: ISession) => {
  try {
    const eternalSession = getEternalSession()
    const initSession = getInitialSession();
    const toUpdatedSession = {
      ...initSession,
      ...eternalSession,
      ...session,
    };
    cookieService.set('session', JSON.stringify(toUpdatedSession));
  } catch (e) {
    console.error(e);
  }
};


export enum ESuperOfferStatus {
  init = 'init',
  pending = 'pending',
  fulfilled = 'fulfilled',
  canceled = 'canceled'
}

export interface ISuperOffer {
  status?: `${ESuperOfferStatus}`;
  reason?: `${ESubscriptionReason}`
}